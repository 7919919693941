import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Article.module.scss';

import Picture from '../Picture/Picture';
import Button from '../Button/Button';
import Byline from '../Byline/Byline';
import { TrackingContext } from '../StreamField/StreamField';
import { trackCtaClickStartpage } from '../../utils/datalayer';

const Article = ({ headline, author, quote, card, imageRight, link }) => {
    const isStartpage = useContext(TrackingContext) !== null;

    return (
        <article
            className={classNames(styles['Article'], {
                [styles['Article--ImageRight']]: imageRight,
            })}
        >
            {card && (
                <div className={styles['Article__Image']}>
                    <Picture {...card} size="large" />
                </div>
            )}

            <div
                className={classNames(styles['Article__TextContainer'], {
                    [styles['Article__TextContainer--ImageRight']]: imageRight,
                })}
            >
                {headline && (
                    <header className={styles['Article__Header']}>
                        <h2 className={styles['Article__Headline']}>
                            {headline}
                        </h2>
                    </header>
                )}

                {author && (author.name || author.title) && (
                    <div className={styles['Article__BylineContainer']}>
                        <Byline
                            image={author.image}
                            size="small"
                            author={author}
                            bylineColor="dark"
                        />
                    </div>
                )}

                {quote && (
                    <div className={styles['Article__Preamble']}>
                        <blockquote className={styles['Article__Quote']}>
                            {quote}
                        </blockquote>
                    </div>
                )}

                {link && (
                    <div className={styles['Article__LinkContainer']}>
                        <Button
                            href={link.href}
                            text={link.label}
                            srText={`${link.label}: ${headline}`}
                            type="tertiary"
                            icon="arrow"
                            iconSize="large"
                            iconColor="pink"
                            buttonColor="pink"
                            onClick={() => {
                                if(isStartpage) {
                                    trackCtaClickStartpage();
                                }
                            }}
                        />
                    </div>
                )}
            </div>
        </article>
    );
};

Article.propTypes = {
    imageRight: PropTypes.bool,
    author: PropTypes.object,
    quote: PropTypes.string,
    href: PropTypes.string,
    card: PropTypes.object,
    headline: PropTypes.string,
};

Article.defaultProps = {
    author: null,
    quote: null,
    href: null,
    card: null,
    headline: null,
    imageRight: false,
};

export default Article;
